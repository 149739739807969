import React, { memo, useCallback, useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';
import { paginationUtils } from 'utils';

import { Columns, PaginationFilters } from 'types';

type Row = Record<string, any>;

type Props = {
  readonly rows: Row[];
  readonly headers: Columns[];
  readonly paginationFilter: PaginationFilters;
};

export const SortableTable: React.FC<Props> = memo(
  ({ rows, headers, paginationFilter }) => {
    const [tableData, setTableData] = useState(rows);
    const [sortConfig, setSortConfig] = useState({
      key: '',
      direction: 'ascending'
    });

    useEffect(() => {
      if (tableData !== rows) {
        setTableData(tableData);
      }
    }, [rows, tableData]);

    const handleSort = useCallback(
      (key: string) => {
        let direction = 'ascending';

        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
          direction = 'descending';
        }

        const sortedData = [...tableData].sort((a, b) => {
          const valueA = a[key] ?? '';
          const valueB = b[key] ?? '';

          return (
            valueA.toString().localeCompare(valueB.toString(), 'en', {
              numeric: true
            }) * (direction === 'ascending' ? 1 : -1)
          );
        });

        setTableData(sortedData);
        setSortConfig({ key, direction });
      },
      [sortConfig, tableData]
    );
    const getSortIcon = useCallback(
      (columnKey: string): 'ascending' | 'descending' | undefined => {
        if (sortConfig.key !== columnKey) return undefined;
        return sortConfig.direction === 'ascending'
          ? 'ascending'
          : 'descending';
      },
      [sortConfig]
    );

    return (
      <Table stackable padded celled striped sortable>
        <Table.Header>
          <Table.Row>
            {headers.map(header => {
              return (
                <Table.HeaderCell
                  collapsing
                  key={header.key}
                  onClick={() => handleSort(header.key)}
                  sorted={getSortIcon(header.key)}
                >
                  {header.label}
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {paginationUtils
            .getActiveItems({ ...paginationFilter, items: tableData })
            .map((item: any, index) => {
              return (
                <Table.Row key={index}>
                  {headers.map(header => {
                    return (
                      <Table.Cell key={header.key}>
                        {header.render
                          ? header.render(item[header.key])
                          : item[header.key] ?? '-'}
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    );
  }
);
