import React, { memo } from 'react';
import { Container } from 'semantic-ui-react';

import { reportUtils } from 'utils';

import { InternalContactUsage, PartnerContactUsage } from 'interfaces';

import { ActiveContactsTable } from './ActiveContactsTable/ActiveContactsTable';
import { ActiveTopologiesTable } from './ActiveTopologiesTable/ActiveTopologiesTable';
import { DepartmentTable } from './DepartmentTable/DepartmentTable';
import { PartnerCompanyTable } from './PartnerCompanyTable/PartnerCompanyTable';

type Props = {
  readonly loading: boolean;
  readonly filters: string;
  readonly topActive: number;
  readonly departmentUsage: InternalContactUsage[];
  readonly partnerCompanyUsage: PartnerContactUsage[];
  readonly topActiveTopology: (InternalContactUsage | PartnerContactUsage)[];
  readonly topActiveContacts: (InternalContactUsage | PartnerContactUsage)[];
};

export const UsageTable: React.FC<Props> = memo(
  ({
    loading,
    filters,
    topActive,
    departmentUsage,
    partnerCompanyUsage,
    topActiveTopology,
    topActiveContacts
  }) => {
    return (
      <div className='table-reports'>
        <Container className='table-report'>
          <b>Usage by Department</b>
          <DepartmentTable
            response={reportUtils.getDepartmentUsage(departmentUsage)}
            searchText={filters}
            loading={loading}
          />
        </Container>
        <Container className='table-report'>
          <b>Usage by Partner company</b>
          <PartnerCompanyTable
            response={reportUtils.getPartnerCompanyUsage(partnerCompanyUsage)}
            searchText={filters}
            loading={loading}
          />
        </Container>
        <Container className='table-report'>
          <b>Top {topActive} active Topologies</b>
          <ActiveTopologiesTable
            response={reportUtils.getTopTopologies(topActiveTopology)}
            searchText={filters}
            loading={loading}
            limit={topActive}
          />
        </Container>
        <Container className='table-report'>
          <b>Top {topActive} most active Users</b>
          <ActiveContactsTable
            response={reportUtils.getTopContacts(topActiveContacts)}
            searchText={filters}
            loading={loading}
            limit={topActive}
          />
        </Container>
      </div>
    );
  }
);
