import React, { memo, useState } from 'react';
import { Pagination, PaginationProps } from 'semantic-ui-react';
import { paginationUtils } from 'utils/pagination';
import { convertTime } from 'utils/utilFunctions';

import { LoadingState } from 'components/LoadingState/LoadingState';
import { SortableTable } from 'components/SortableTable';
import { REPORT_PAGE_SIZE } from 'constants/pagination';
import { DepartmentUsage } from 'interfaces';
import { Columns, PaginationFilters } from 'types';

import { filterDepartmentItems } from '../ReportFilterHelpers';

import './styles.less';

type Props = {
  readonly response: DepartmentUsage[];
  readonly searchText: string;
  readonly loading: boolean;
};

export const DepartmentTable: React.FC<Props> = memo(
  ({ response, searchText, loading }) => {
    const [pagination, setPagination] = useState<PaginationFilters>({
      activePage: 1,
      pageSize: REPORT_PAGE_SIZE
    });
    const { total, items } = filterDepartmentItems(response, searchText);
    const totalPages = paginationUtils.getTotalPages({
      pageSize: pagination.pageSize,
      totalCount: total
    });
    const headers: Columns[] = [
      {
        key: 'department',
        label: 'Department',
        render: (value: string) => value
      },
      {
        key: 'totalTopologies',
        label: 'Number of topologies',
        render: (value: number) => value
      },
      {
        key: 'activeSeconds',
        label: 'Billable Time',
        render: (value: number) => convertTime(value)
      }
    ];
    if (loading) {
      return <LoadingState />;
    }
    return (
      <>
        <SortableTable
          rows={items}
          headers={headers}
          paginationFilter={pagination}
        />
        {totalPages > 0 && (
          <div className='pagination'>
            <Pagination
              activePage={pagination.activePage}
              totalPages={totalPages}
              pointing
              secondary
              onPageChange={(
                event: React.MouseEvent<HTMLAnchorElement>,
                data: PaginationProps
              ) =>
                setPagination({
                  ...pagination,
                  activePage: data.activePage as number
                })
              }
            />
          </div>
        )}
      </>
    );
  }
);
