import { ContactUsage } from 'types';

export const filterContacts = (
  contacts: ContactUsage[],
  searchText: string
): {
  total: number;
  items: ContactUsage[];
} => {
  const items = contacts.filter(contact => {
    let valid = true;
    if (searchText) {
      const text = searchText.toLowerCase();
      valid =
        valid &&
        (contact.name.toLowerCase().includes(text) ||
          contact.email.toLowerCase().includes(text) ||
          contact.company.toLowerCase().includes(text) ||
          contact.createdBy.toLowerCase().includes(text));
    }
    return valid;
  });
  return {
    total: items.length,
    items
  };
};
