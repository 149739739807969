import { paginationUtils } from 'utils/pagination';

import {
  DepartmentUsage,
  PartnerCompanyUsage,
  TopContactUsage
} from 'interfaces';
import { PaginationFilters, TopTopologyUsage } from 'types';

export const getActiveItems = (pagination: PaginationFilters, items: any) => {
  return paginationUtils.getActiveItems({
    ...pagination,
    items
  });
};

export const filterPartnerCompanyItems = (
  data: PartnerCompanyUsage[],
  searchText: string
): {
  total: number;
  items: PartnerCompanyUsage[];
} => {
  const items = data.filter(d => {
    let valid = true;
    if (searchText) {
      const text = searchText.toLowerCase();
      valid = valid && d.partnerCompany.toLowerCase().includes(text);
    }
    return valid;
  });

  return {
    total: items.length,
    items
  };
};

export const filterDepartmentItems = (
  data: DepartmentUsage[],
  searchText: string
): {
  total: number;
  items: DepartmentUsage[];
} => {
  const items = data.filter(d => {
    let valid = true;
    if (searchText) {
      const text = searchText.toLowerCase();
      valid = valid && d.department.toLowerCase().includes(text);
    }
    return valid;
  });

  return {
    total: items.length,
    items
  };
};

export const filterTopTopologyItems = (
  data: TopTopologyUsage[],
  searchText: string
): TopTopologyUsage[] => {
  const items = data.filter(d => {
    let valid = true;
    if (searchText) {
      const text = searchText.toLowerCase();
      valid =
        valid &&
        (d.name.toLowerCase().includes(text) ||
          d.owner.toLowerCase().includes(text) ||
          d.departmentOrCompany.toLowerCase().includes(text));
    }
    return valid;
  });

  return items;
};

export const filterTopContactItems = (
  data: TopContactUsage[],
  searchText: string
): TopContactUsage[] => {
  const items = data.filter(d => {
    let valid = true;
    if (searchText) {
      const text = searchText.toLowerCase();
      valid =
        valid &&
        (d.name.toLowerCase().includes(text) ||
          d.departmentOrCompany.toLowerCase().includes(text));
    }
    return valid;
  });

  return items;
};
