import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dropdown,
  DropdownItemProps,
  Input,
  InputOnChangeData
} from 'semantic-ui-react';

import { topActiveOptions } from 'constants/reports';
import { DatesByPage, FiltersByPage } from 'types';

import { setDatesInputRange, setReportFilters } from 'actions/actions';
import { RootState } from 'Reducers/contactReducer';
import { DatePicker } from './DatePicker';

import './styles.less';

type Props = {
  readonly searchText: string;
  readonly setSearchText: (value: string) => void;
  readonly reportType: string;
  readonly setEmailModalVisible: (value: boolean) => void;
  readonly topActive?: number;
  readonly setTopActive?: (value: number) => void;
};

export const PeriodFilterSelector: React.FC<Props> = memo(
  ({
    searchText,
    setSearchText,
    reportType,
    topActive,
    setEmailModalVisible,
    setTopActive
  }) => {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');

    const datesByPage: DatesByPage = useSelector(
      (state: RootState) => state.dates
    );
    const filtersByPage: FiltersByPage = useSelector(
      (state: RootState) => state.filters
    );
    const isTopos = reportType === 'topology';

    const handleChange = (data: any) => {
      let dates = datesByPage;
      if (data.name === 'startDate') {
        setStartDate(data.value);
        if (isTopos) {
          dates = {
            ...dates,
            topology: { startDate: data.value, endDate }
          };
        } else {
          dates = {
            ...dates,
            contact: { startDate: data.value, endDate }
          };
        }
        dispatch(setDatesInputRange(dates));
      } else if (data.name === 'endDate') {
        if (isTopos) {
          dates = {
            ...dates,
            topology: { startDate, endDate: data.value }
          };
        } else {
          dates = {
            ...dates,
            contact: { startDate, endDate: data.value }
          };
        }
        setEndDate(data.value);
        dispatch(setDatesInputRange(dates));
      }
    };

    const onFilterInput = (data: InputOnChangeData) => {
      let filters = filtersByPage;
      setSearchText(data.value);
      if (isTopos) {
        filters = {
          ...filters,
          topologyFilterText: data.value
        };
      } else {
        filters = {
          ...filters,
          contactFilterText: data.value
        };
      }
      dispatch(setReportFilters(filters));
    };
    const storedValues = isTopos
      ? {
          dates: datesByPage.topology,
          filter: filtersByPage.topologyFilterText
        }
      : { dates: datesByPage.contact, filter: filtersByPage.contactFilterText };
    return (
      <div className='cl-reports-period-selector'>
        <div className='calendar-container'>
          <div className='calendar-input'>
            <div className='button-float'>
              <Button
                className='float-action'
                basic
                secondary
                onClick={() => setEmailModalVisible(true)}
              >
                Send Email with Report
              </Button>
            </div>
            <div className='padding-calendar'>
              Start
              <DatePicker
                placeholderText='Start Date'
                setDateTimeValue={(data: any) => handleChange(data)}
                pickerType='startDate'
                dateTimeValue={startDate || storedValues.dates.startDate}
              />
            </div>
          </div>
          <div className='calendar-input'>
            <div className='padding-calendar end-calendar'>
              End
              <DatePicker
                placeholderText='End Date'
                setDateTimeValue={(data: any) => handleChange(data)}
                pickerType='endDate'
                dateTimeValue={endDate || storedValues.dates.endDate}
              />
            </div>
          </div>
          <div className='calendar-input'>
            <div className='padding-calendar'>
              Filter
              <Input
                className='calendar-input input-margin'
                fluid
                placeholder='Filter usage table'
                value={searchText || storedValues.filter}
                onClick={(e: any) => {
                  e.stopPropagation();
                }}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                  data: InputOnChangeData
                ) => {
                  onFilterInput(data);
                }}
                action={
                  isTopos && (
                    <Dropdown button icon='filter'>
                      <Dropdown.Menu>
                        <Dropdown.Menu scrolling>
                          <Dropdown.Header
                            icon='tags'
                            content='Show Top Active in:'
                          />
                          {topActiveOptions.map(option => (
                            <Dropdown.Item
                              key={option.key}
                              text={option.text}
                              value={option.value}
                              active={topActive === option.value}
                              onClick={(
                                event: React.MouseEvent<HTMLDivElement>,
                                data: DropdownItemProps
                              ) =>
                                setTopActive &&
                                setTopActive(data.value as number)
                              }
                            />
                          ))}
                        </Dropdown.Menu>
                      </Dropdown.Menu>
                    </Dropdown>
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
