import React, { memo, useState } from 'react';
import { Pagination, PaginationProps } from 'semantic-ui-react';
import { paginationUtils } from 'utils/pagination';

import { LoadingState } from 'components/LoadingState/LoadingState';
import { SortableTable } from 'components/SortableTable';
import { DEFAULT_PAGE_SIZE } from 'constants/pagination';
import { Columns, ContactUsage, PaginationFilters } from 'types';

import { filterContacts } from './FilterContacts';

type Props = {
  readonly loading: boolean;
  readonly searchText: string;
  readonly response: ContactUsage[];
};

export const ContactUsageTable: React.FC<Props> = memo(
  ({ searchText, response, loading }) => {
    const [pagination, setPagination] = useState<PaginationFilters>({
      activePage: 1,
      pageSize: DEFAULT_PAGE_SIZE
    });
    const { total, items } = filterContacts(response, searchText);
    const totalPages = paginationUtils.getTotalPages({
      pageSize: pagination.pageSize,
      totalCount: total
    });
    const headers: Columns[] = [
      {
        key: 'name',
        label: 'Name',
        render: (value: string) => value
      },
      {
        key: 'email',
        label: 'Email',
        render: (value: string) => value
      },
      {
        key: 'company',
        label: 'Company',
        render: (value: string) => value
      },
      {
        key: 'createdBy',
        label: 'Created by',
        render: (value: string) => value
      },
      {
        key: 'eventTime',
        label: 'Event time',
        render: (value: string) => new Date(value).toTimeString()
      },
      {
        key: 'totalTopologies',
        label: 'Number of topologies',
        render: (value: number) => value
      }
    ];
    if (loading) {
      return <LoadingState />;
    }
    return (
      <div>
        <SortableTable
          rows={items}
          headers={headers}
          paginationFilter={pagination}
        />
        {totalPages > 0 && (
          <div className='pagination-wrapper'>
            <Pagination
              activePage={pagination.activePage}
              totalPages={totalPages}
              pointing
              secondary
              onPageChange={(
                event: React.MouseEvent<HTMLAnchorElement>,
                data: PaginationProps
              ) =>
                setPagination({
                  ...pagination,
                  activePage: data.activePage as number
                })
              }
            />
          </div>
        )}
      </div>
    );
  }
);
